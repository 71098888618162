import eventually from 'wix-eventually';
import { ComponentRef } from '@wix/editor-platform-sdk-types/dist/types/common';
import { WidgetInstallationType, EditorSDK } from '@wix/platform-editor-sdk';
import { PerksWidgetRole, PlanWidgetRole } from '@wix/pricing-plans-common/blocks';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import pricingPlansIllustration from '../assets/images/pricing-plans.png';
import SinglePlanWidget from '../components/SinglePlanWidget/.component.json';
import { SinglePlanInteractions } from '../types/SinglePlanFedops';
import { toError } from '../utils/errors';
import {
  assertSinglePlanWidgetExists,
  setFirstPlanToWidget,
  getContainerRef,
  getPlanWidget,
  getRootWidget,
} from '../utils/widget';
import { RootPresetId, SINGLE_PLAN_DEFAULT_PRESETS_BY_STATE, WidgetState } from './layout/single-plan';

export async function addSinglePlanWidget(params: {
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
  planId?: string;
}) {
  const { editorSDK, flowAPI, planId } = params;
  const defaultPlanPreset = SINGLE_PLAN_DEFAULT_PRESETS_BY_STATE[WidgetState.Default];
  flowAPI.fedops.interactionStarted(SinglePlanInteractions.AddWidget);
  const refComponent = await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: SinglePlanWidget.id,
    installationType: WidgetInstallationType.Closed,
    layout: {
      width: defaultPlanPreset.width,
      height: defaultPlanPreset.height,
      x: 0,
      y: 100,
    },
    scopedPresets: {
      desktop: {
        layout: RootPresetId.Desktop,
        style: RootPresetId.Desktop,
      },
    },
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        hidden: false,
        height: {
          type: 'auto',
        },
        minHeight: {
          type: 'px',
          value: defaultPlanPreset.height,
        },
        width: {
          type: 'px',
          value: defaultPlanPreset.width,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'center',
        justifySelf: 'center',
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        margins: {
          top: {
            type: 'px',
            value: 0,
          },
          left: {
            type: 'px',
            value: 0,
          },
        },
      },
      containerLayout: {
        type: 'GridContainerLayout',
        columns: [{ type: 'fr', value: 1 }],
        rows: [{ type: 'fr', value: 1 }],
      },
    },
  });
  flowAPI.fedops.interactionEnded(SinglePlanInteractions.AddWidget);

  onSinglePlanWidgetAddedToStage({ editorSDK, refComponent, flowAPI, planId });
}

export async function onSinglePlanWidgetAddedToStage(params: {
  editorSDK: EditorSDK;
  refComponent: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
  planId?: string;
}) {
  const { editorSDK, refComponent, flowAPI, planId } = params;
  try {
    if (isComponentInitializing(refComponent.id)) {
      return;
    }
    openProgressBar({ editorSDK, flowAPI });
    setIsComponentInitializing(refComponent.id);
    flowAPI.fedops.interactionStarted(SinglePlanInteractions.InitializeInEditor);
    /*
      When adding Single Plan widget from Add Panel immediately
      after installing Pricing Plans, a bug occurs, where component
      is added to the editor, but the widget component is not returned
      by editor SDK, which breaks our widget initialization.

      To avoid this, making sure that widget exists before proceeding
    */
    flowAPI.fedops.interactionStarted(SinglePlanInteractions.AssertWidgetExists);
    await eventually(async () => assertSinglePlanWidgetExists(editorSDK, refComponent), {
      interval: 100,
      timeout: 5000,
    });
    updateProgressBar({ editorSDK, step: 1 });
    flowAPI.fedops.interactionEnded(SinglePlanInteractions.AssertWidgetExists);

    await initNewSinglePlanWidget({ editorSDK, flowAPI, componentRef: refComponent, planId });
    flowAPI.fedops.interactionEnded(SinglePlanInteractions.InitializeInEditor);
    closeProgressBar({ editorSDK });
  } catch (e) {
    console.log(e);
    editorSDK.components.remove('', { componentRef: refComponent });
    closeProgressBar({ editorSDK, isError: true });
    flowAPI.errorMonitor.captureException(toError(e));
  } finally {
    removeIsComponentInitializing(refComponent.id);
  }
}

async function initNewSinglePlanWidget(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
  planId?: string;
}) {
  const { editorSDK, componentRef, flowAPI, planId } = params;
  const planWidget = await getPlanWidget(editorSDK, componentRef);
  const defaultPlanPreset = SINGLE_PLAN_DEFAULT_PRESETS_BY_STATE[WidgetState.Default];
  const planWidgetParentRef = await getContainerRef(editorSDK, planWidget);

  flowAPI.fedops.interactionStarted(SinglePlanInteractions.SetInitialPresets);
  await editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef,
    layoutPresetId: RootPresetId.Mobile,
    stylePresetId: RootPresetId.Mobile,
    context: {
      viewport: 'MOBILE',
    },
  });

  await editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef: planWidgetParentRef,
    layoutPresetId: defaultPlanPreset.id,
    stylePresetId: defaultPlanPreset.id,
    context: {
      viewport: 'MOBILE',
    },
  });

  await editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef: planWidgetParentRef,
    layoutPresetId: defaultPlanPreset.id,
    stylePresetId: defaultPlanPreset.id,
    context: {
      viewport: 'DESKTOP',
    },
  });
  flowAPI.fedops.interactionEnded(SinglePlanInteractions.SetInitialPresets);

  flowAPI.fedops.interactionStarted(SinglePlanInteractions.SetInitialInnerWidgetState);
  collapsePerkDivider(editorSDK, componentRef);
  setDefaultButtonText({ editorSDK, rootWidgetRef: componentRef, flowAPI });
  setDefaultRibbonText({ editorSDK, rootWidgetRef: componentRef, flowAPI });
  flowAPI.fedops.interactionEnded(SinglePlanInteractions.SetInitialInnerWidgetState);

  updateProgressBar({ editorSDK, step: 2 });
  flowAPI.fedops.interactionStarted(SinglePlanInteractions.SetInitialPlan);
  const rootWidget = await getRootWidget(editorSDK, planWidget);
  if (planId) {
    await editorSDK.document.application.appStudioWidgets.props.set('', {
      widgetRef: rootWidget,
      newProps: { planId },
    });
  } else {
    try {
      await setFirstPlanToWidget({
        widgetRef: rootWidget,
        httpClient: flowAPI.httpClient,
        editorSDK,
      });
    } catch (e) {
      flowAPI.errorMonitor.captureException(toError(e));
    }
  }
  updateProgressBar({ editorSDK, step: 3 });
  await editorSDK.application.livePreview.refresh('', {
    shouldFetchData: true,
    source: 'CONNECTED_COMPONENT_ADDED',
  });
  flowAPI.fedops.interactionEnded(SinglePlanInteractions.SetInitialPlan);
}

async function collapsePerkDivider(editorSDK: EditorSDK, rootWidgetRef: ComponentRef) {
  const planComponent = await getPlanWidget(editorSDK, rootWidgetRef);
  const [perksWidget] = await editorSDK.components.findAllByRole('', {
    controllerRef: planComponent,
    role: PlanWidgetRole.PerksWidget,
  });
  const [perkDivider] = await editorSDK.components.findAllByRole('', {
    controllerRef: perksWidget,
    role: PerksWidgetRole.PerkDivider,
  });
  return editorSDK.components.refComponents.collapseReferredComponent('token', {
    componentRef: perkDivider,
  });
}

async function setDefaultButtonText(params: {
  editorSDK: EditorSDK;
  rootWidgetRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, rootWidgetRef, flowAPI } = params;
  const planComponent = await getPlanWidget(editorSDK, rootWidgetRef);
  const [button] = await editorSDK.components.findAllByRole('', {
    controllerRef: planComponent,
    role: PlanWidgetRole.Button,
  });
  await editorSDK.components.data.update('', {
    componentRef: button,
    data: { label: flowAPI.translations.t('blocks.default-button-text') },
  });
}

async function setDefaultRibbonText(params: {
  editorSDK: EditorSDK;
  rootWidgetRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, rootWidgetRef, flowAPI } = params;
  const planWidget = await getPlanWidget(editorSDK, rootWidgetRef);
  const [ribbonWidget] = await editorSDK.components.findAllByRole('', {
    controllerRef: planWidget,
    role: PlanWidgetRole.RibbonWidget,
  });
  await editorSDK.application.appStudioWidgets.props.set('', {
    widgetRef: ribbonWidget,
    newProps: { text: flowAPI.translations.t('blocks.default-badge-text') },
  });
}

const componentInitializingCache = new Set<string>();
function setIsComponentInitializing(id: string) {
  componentInitializingCache.add(id);
}

function isComponentInitializing(id: string) {
  return componentInitializingCache.has(id);
}

function removeIsComponentInitializing(id: string) {
  return componentInitializingCache.delete(id);
}

function openProgressBar({ editorSDK, flowAPI }: { editorSDK: EditorSDK; flowAPI: EditorScriptFlowAPI }) {
  return editorSDK.editor.openProgressBar('', {
    title: flowAPI.translations.t('blocks.add-widget-progress-bar.title'),
    totalSteps: 3,
    image: pricingPlansIllustration,
  });
}

function updateProgressBar({ editorSDK, step }: { editorSDK: EditorSDK; step: 1 | 2 | 3 }) {
  return editorSDK.editor.updateProgressBar('', { currentStep: step });
}

function closeProgressBar({ editorSDK, isError }: { editorSDK: EditorSDK; isError?: boolean }) {
  return editorSDK.editor.closeProgressBar('', { isError });
}
